const banner = document.getElementById('banner');
const header = document.getElementById('page-header');

var interval;
function setup() {
    var index = parseInt(getComputedStyle(banner).getPropertyValue('--index'));
    const startingIndex = index;

    interval = setInterval(() => {
        index -= 1;
        banner.style.setProperty('--index', index);

        // cambia numero di elementi ↓
        if (index == startingIndex - 4) {
            setTimeout(() => {
                banner.classList.remove('transition-transform');
                setTimeout(() => {
                    index = startingIndex;
                    banner.style.setProperty('--index', index);
                    setTimeout(() => {
                        banner.classList.add('transition-transform');
                    }, 1250);
                }, 1250);
            }, 1250);
        }
    }, 5000);
}


function hideBanner() {
    header.classList.add('hidden');
    document.documentElement.style.setProperty("--header-height", "7.5vh");
}

window.addEventListener('resize', () => {
    clearInterval(interval);
    setup();
})

window.addEventListener('load', () => {
    setup();
})